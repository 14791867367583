import request from '@/utils/request'


//考核项目任务
//获取考核项目列表
export function getAssessList(pageid) {
    return request({
        url: '/admin/assess/list/' + pageid,
        method: 'get'
    })
}
//获取考核项目详细信息
export function getAssess(id) {
    return request({
        url: '/admin/assess/' + id,
        method: 'get'
    })
}
//新增考核项目
export function StoreAssess(data) {
    return request({
        url: '/admin/assess/',
        method: 'post',
        data: data
    })
}
//修改考核项目
export function updateAssess(data) {
    return request({
        url: '/admin/assess/' + data.id,
        method: 'PUT',
        data: data
    })
}
//删除考核项目
export function DelAssess(id) {
    return request({
        url: '/admin/assess/' + id,
        method: 'DELETE'
    })
}

//发布取消任务快捷
export function pushTask(id) {
    return request({
        url: '/admin/assess/push/' + id,
        method: 'GET'
    })
}

//获取考核人员分数列表信息
export function getAssessUserList(data) {
    return request({
        url: '/admin/assess/res/' + `${data.id}/?tid=${data.tid}&level=${data.level}`,
        method: 'GET'
    })
}





//获取考核子项列表
export function getAssessSubList(pageid) {
    return request({
        url: '/admin/assess/sub/list/' + pageid,
        method: 'get'
    })
}
//新增考核子项
export function StoreAssessSub(data) {
    return request({
        url: '/admin/assess/sub/',
        method: 'post',
        data: data
    })
}

//获取考核子项信息
export function getAssessSub(id) {
    return request({
        url: '/admin/assess/sub/' + id,
        method: 'get'
    })
}
//修改考核子项信息
export function updateAssessSub(data) {
    return request({
        url: '/admin/assess/sub/' + data.id,
        method: 'PUT',
        data: data
    })
}
//删除考核子项信息
export function DelAssessSub(id) {
    return request({
        url: '/admin/assess/sub/' + id,
        method: 'DELETE'
    })
}


////////////////考核加分项api///////////
//获取列表
export function getMerit() {
    return request({
        url: '/admin/assess/merit/list',
        method: 'GET'
    })
}
//新增
export function AddMerit(data) {
    return request({
        url: '/admin/assess/merit/add',
        data,
        method: 'POST'
    })
}
//删除
export function DelMerit(id) {
    return request({
        url: '/admin/assess/merit/' + id,
        method: 'DELETE'
    })
}

///////////一票否决
export function vetoUser(data) {
    return request({
        url: '/admin/assess/veto',
        data,
        method: 'POST'
    })
}
///////////添加加分项考核API////////
//获取列表
export function getAssessTeamList(id) {
    return request({
        url: '/admin/assess/team/list/' + id,
        method: 'GET'
    })
}
//获取人员列表
export function getAssessTeamUserList(id) {
    return request({
        url: '/admin/assess/team/user/list/' + id,
        method: 'GET'
    })
}
//////提交加分项
export function addPlus(data) {
    return request({
        url: '/admin/assess/addPlus',
        data,
        method: 'POST'
    })
}
/////获取加分项人员列表
export function getAssessMeritUserList(id, pageid) {
    return request({
        url: '/admin/assess/meritlist/' + id + '/' + pageid,
        method: 'GET'
    })
} 
///删除加分项人员
export function DelAssessMeritUser(id) {
    return request({
        url: '/admin/assess/meritlist/' + id,
        method: 'DELETE'
    })
} 
////获取考核进度详情
export function getProcessInfoList(id, pageid) {
    return request({
        url: '/admin/assess/process/' + id + '/' + pageid,
        method: 'GET'
    })
} 