<template>
  <div class="top">
    <el-button type="primary" size="mini" @click="OpenForm()">新增</el-button>
  </div>
  <ListInfo ref="showForm" @fetchData="fetchData" />
  <el-table :data="tableData" border size="mini" :height="`calc(100vh - 210px)`" :highlight-current-row="true"
    style="width: 100%">
    <el-table-column type="selection" width="55" />
    <el-table-column type="index" :index="indexMethod" label="序号" prop="id" />
    <el-table-column prop="name" align="center" label="名称" />
    <el-table-column prop="score" align="center" label="分值" />
    <el-table-column label="删除" align="center">
      <template #default="{ row }">
        <el-popconfirm title="是否删除加分项?" @confirm="tableDelClick(row)">
          <template #reference>
            <el-button type="danger" :icon="Delete" size="mini" circle></el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>


</template>

<script setup>
import { toRefs, reactive, onBeforeMount, ref, getCurrentInstance } from "vue";
import { getMerit, DelMerit } from "@/api/assess.js";
import { Delete } from "@element-plus/icons";
import ListInfo from "../merit/components/list.vue";
let { proxy } = getCurrentInstance();
const showForm = ref(null);
//添加 修改
let OpenForm = () => {
  proxy.$nextTick(() => {
    showForm.value.showModal();
  });
};

//删除
let tableDelClick = (row) => {
  DelMerit(row.id).then((response) => {
    showForm.value.eleMessage(response);
    fetchData();
  });
};

const state = reactive({
  tableData: null,
});
onBeforeMount(() => {
  fetchData();
});
const fetchData = () => {
  getMerit().then((response) => {
    state.tableData = response.data.list;
  });
};
const indexMethod = (index) => {
  return index + 1;
};

//导出属性到页面中使用
let { tableData } = toRefs(state);
</script>

<style scoped>
.top {
  margin-bottom: 20px;
}

.el-pagination {
  text-align: center;
}
</style>
